// src/components/googleAdsense.js

import React, { useEffect } from 'react'

export const Adsense = ({ path }) => {
  useEffect(() => {
    // console.log('El componente Adsense se ha cargado en la web');
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [path])
  
  return (
    <div className="adsense-container">
    <ins 
      className="adsbygoogle"
      style={{ "display": "block" , textAlign: "center"  }}
      data-ad-client="ca-pub-2292291997787849"
      data-ad-slot="1639716856"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
    </div>
  )
}